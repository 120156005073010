import Styled, { keyframes, css } from 'styled-components';
import { baseMixin } from '../mixins';

import HeroImage from '../../components/heroImage';

const slide = keyframes`
  from {
    transform: translateX(-6px);
  }

  to {
    transform: translateX(6px);
  }
`;

const slideAnimation = css`
  animation: ${slide} 1s ease-in-out infinite alternate;
`;

const Wrapper = Styled.div`
  ${baseMixin};
  font-family: lato, sans-serif;

  .services-section {
    max-width: 1170px;
    margin: 60px auto;

    h2 {
      text-align: center;
      margin-bottom: 30px;
    }

    h3 {
      font-size: 24px;
      font-weight: 500;
    }

    .image-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .our-process-section {
    max-width: 1170px;
    margin: 60px auto;

    h2 {
      font-size: 30px;
      text-align: center;
      margin-bottom: 50px;
    }

    h4 {
      margin-top: 0;
      font-size: 18px;
      font-weight: 700;
    }

    img {
      border-radius: 6px;
    }
  }

  .cta-section {
    background-color: #3676D9;
    color: white;
    padding: 80px 0;

    .cta-section__inner-wrapper {
      margin: 0 auto;
      text-align: center;
      padding: 0 24px;
    }

    h2 {
      font-size: 30px;
      margin-bottom: 50px;
    }

    p {
      margin-bottom: 12px;
    }

    h3 {
      margin-top: 48px;
      font-size: 24px;
    }

    .animated-right-arrow {
      display: inline-block;
      margin: 0 12px;
      ${slideAnimation};
    }

    img {
      padding-top: 2px;
    }

    a {
      color: white;
      font-size: 24px;
    }

    @media screen and (max-width: 540px) {
      .breaker {
        display: block;
        margin-bottom: 12px;
      }

      .animated-right-arrow {
        display: none;
      }
    }
  }

  .testimonial-section {
    .testimonial-section__inner-wrapper {
      width: 700px;
      margin: 0 auto;
    }

    @media screen and (max-width: 760px) {
      .testimonial-section__inner-wrapper {
        width: 90%;
      }
    }

    h2 {
      text-align: center;
      font-size: 30px;
      margin-bottom: 50px;
    }

    p {
      font-size: 16px;
    }

    blockquote {
      border-left: 5px solid black;
      border-color: #EEEEEE;
      padding-left: 20px;
      margin-bottom: 30px;
  
      p {
        font-size: 16px;
      }
    
      footer {
        font-style: italic;
        font-size: 14px;
    
        &:before {
          content: '\\2014 ';
        }
      }
    }

    @media screen and (max-width: 760px) {
      blockquote {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .page-footer {
    padding: 24px 48px;
    padding-bottom: 108px;
    background-color: #3C3D41;
    color: white;

    h2, p {
      text-align: center;
    }

    h2 {
      font-size: 30px;
      margin-bottom: 24px;
    }

    p {
      font-size: 24px;
      margin-bottom: 48px;
    }

    a {
      color: white;
      margin-left: 24px;
      
      &:hover {
        text-decoration: none;
      }
    }

    hr {
      margin: 20px 0 48px 0;
    }

    h4 {
      font-size: 18px;
      font-style: italic;
      margin-bottom: 1em;
      font-weight: 700;
    }

    .back-to-section {
      ul {
        list-style-type: none;
        padding-left: 0;

        li {
          margin-bottom: 12px;

          a {
            margin-left: 0;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .get-in-touch {
      a {
        margin: 0 0 12px 0;
      }
    }
  }
`;

const HeroImageWrapper = Styled(HeroImage)`
  .hero-image__inner-wrapper {
    text-align: center;
    font-family: lato, sans-serif;
    max-width: 60%;
    margin: 0 auto;

    h1 {
      font-size: 4em;
      margin-top: 0;
    }

    p {
      text-transform: none;
      font-size: 21px;
    }
  }

  @media screen and (max-width: 900px) {
    .hero-image__inner-wrapper {
      h1 {
        font-size: 3em;
      }

      p {
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .hero-image__inner-wrapper {
      h1 {
        font-size: 2em;
      }

      p {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 450px) {
    .hero-image__inner-wrapper {
      max-width: 90%;
      
      h1 {
        font-size: 1.5em;
      }

      p {
        font-size: 14px;
      }
    }
  }
`;

const HeroButton = Styled.a`
  background-color: #3676D9;
  padding: 6px 12px;
  border-radius: 3px;
  font-size: 1.5em;
  color white;
  margin-top: 12px;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;

  &:hover {
    background-color: #2f69bf;
    color: white;
    text-decoration: none;
  }
  
  @media screen and (max-width: 900px) {
    font-size: 1.3em;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.2em;
  }
`;

const ThreeColumn = Styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .column {
    width: 30%;
    padding: 0 24px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 991px) {
    .column {
      width: 90%;
    }
  }
`;

const TwoColumn = Styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 3em;

  .image-column {
    max-width: 30%;
    margin-right: 24px;
    margin-bottom: 30px;
    box-sizing: border-box;
  }

  .text-column {
    max-width: 70%;
    box-sizing: border-box;
  }

  @media screen and (max-width: 760px) {
    .image-column {
      width: 90%;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;

      .gatsby-image-wrapper {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .text-column {
      width: 90%;
      max-width: 90%;
      margin: 0 auto;
    }
  }
`;

export { Wrapper, HeroImageWrapper, HeroButton, ThreeColumn, TwoColumn };
