import React from 'react';

import Styled from 'styled-components';

const ScrollToTop = () => {
  return (
    <Wrapper onClick={clickHandler}>
      <span>Top</span>
    </Wrapper>
  );
};

const clickHandler = ev => {
  ev.preventDefault();

  let intervalID = window.setInterval(() => {
    if (window.scrollY === 0) {
      window.clearInterval(intervalID);
    }

    window.scrollTo(0, window.scrollY - 25);
  }, 5);
};

const Wrapper = Styled.div`
  position: fixed;
  bottom: 0;
  right: 24px;
  background-color: rgba(0, 0, 0, .5);
  color: white;
  cursor: pointer;
  width: 60px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: lato, sans-serif;
`;

export default ScrollToTop;
