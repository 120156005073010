import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../layouts/landingPageLayout';

import {
  Wrapper,
  HeroImageWrapper,
  HeroButton,
  ThreeColumn,
  TwoColumn,
} from '../styles/pages/rug-cleaning';

export const query = graphql`
  query rugCleaningPageQuery {
    rugBackdropImage: file(relativePath: { regex: "/rug-backdrop.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rugIcon: file(relativePath: { regex: "/rug-icon.png/" }) {
      childImageSharp {
        fixed(width: 160, height: 86) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    catIcon: file(relativePath: { regex: "/cat-icon.png/" }) {
      childImageSharp {
        fixed(width: 61, height: 86) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    magnifyingGlassIcon: file(
      relativePath: { regex: "/magnifying-glass-icon.png/" }
    ) {
      childImageSharp {
        fixed(width: 83, height: 86) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rugBeatingImage: file(relativePath: { regex: "/rug-beating.jpg/" }) {
      childImageSharp {
        fixed(width: 212, height: 141) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rugWashingImage: file(relativePath: { regex: "/rug-washing.jpg/" }) {
      childImageSharp {
        fixed(width: 212, height: 141) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rugDryingImage: file(relativePath: { regex: "/rug-drying.jpg/" }) {
      childImageSharp {
        fixed(width: 212, height: 141) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fringeDetailingImage: file(
      relativePath: { regex: "/fringe-detailing.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 200, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rugWrappingImage: file(relativePath: { regex: "/rug-wrapping.jpg/" }) {
      childImageSharp {
        fixed(width: 200, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    arrowRight: file(relativePath: { regex: "/arrow-right-white.png/" }) {
      childImageSharp {
        fixed(width: 20, height: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const hero = data => {
  const image = <Img fluid={data.rugBackdropImage.childImageSharp.fluid} />;
  return (
    <HeroImageWrapper image={image}>
      <div className="hero-image__inner-wrapper">
        <h1>We make rugs healthier and cleaner.</h1>
        <p>
          Restore your rugs to like-new condition with our submersion rug
          bathing!
        </p>
        <HeroButton href="tel:1-540-449-0077">540-449-0077</HeroButton>
      </div>
    </HeroImageWrapper>
  );
};

const RugCleaningPage = ({ data }) => {
  return (
    <Layout hero={hero(data)}>
      <Helmet>
        <meta
          name="description"
          content="Premium area and oriental rug cleaning services from Sci-Tech Carpet Cleaning."
        />
        <title>
          Area and Oriental Rug Cleaning -- Sci-Tech Carpet Cleaning
        </title>
      </Helmet>
      <Wrapper>
        <section id="service-benefits" className="services-section">
          <h2>Nobody beats the quality of our rug cleaning services!</h2>
          <ThreeColumn>
            <div className="column">
              <div className="image-container">
                <Img fixed={data.rugIcon.childImageSharp.fixed} />
              </div>
              <h3>Full submersion cleaning</h3>
              <p>
                We fully submerge rugs for maximum soiling removal. This process
                flushes your rugs repeatedly until we push all of the dirt and
                soiling out.
              </p>
            </div>
            <div className="column">
              <div className="image-container">
                <Img fixed={data.catIcon.childImageSharp.fixed} />
              </div>
              <h3>Effective pet odor removal</h3>
              <p>
                Our odor treatments completely eradicate odors like they never
                existed. Our odor treatments are so powerful, they can even
                remove skunk odors!
              </p>
            </div>
            <div className="column">
              <div className="image-container">
                <Img fixed={data.magnifyingGlassIcon.childImageSharp.fixed} />
              </div>
              <h3>Thorough detailing by hand</h3>
              <p>
                Every rug we clean is detailed by hand to groom the fringe and
                treat troublesome spots. This will leave your rugs looking
                better than you imagined.
              </p>
            </div>
          </ThreeColumn>
        </section>
        <hr
          style={{
            marginBottom: '3em',
            marginTop: '20px',
            borderTop: '1px solid #eee',
          }}
        />
        <section id="multi-step-process" className="our-process-section">
          <h2>Check out our multi-step process</h2>
          <TwoColumn>
            <div className="image-column">
              <Img fixed={data.rugBeatingImage.childImageSharp.fixed} />
            </div>
            <div className="text-column">
              <h4>
                Rugs are beaten before being washed to remove dry soils that
                can't be removed by vacuuming alone.
              </h4>
              <p>
                We use a "beating machine" to thoroughly beat dirt and other
                particulates out of the rug. This process is more effective than
                vacuuming and removes a significant amount of dirt from your
                rugs. Alone, this process acts as a dry cleaning for your rug.
              </p>
            </div>
          </TwoColumn>
          <TwoColumn>
            <div className="image-column">
              <Img fixed={data.rugWashingImage.childImageSharp.fixed} />
            </div>
            <div className="text-column">
              <h4>
                We submerge and then pit wash rugs until all of the dirt in the
                rug has been removed.
              </h4>
              <p>
                A high-powered and completely rug-safe cleaner is applied to the
                rug. Then we scrub the rug thoroughly on both sides before
                flushing water through the rugs in our wash pit. This process
                pushes out virtually all of the remaining dirt in the rug.
              </p>
            </div>
          </TwoColumn>
          <TwoColumn>
            <div className="image-column">
              <Img fixed={data.rugDryingImage.childImageSharp.fixed} />
            </div>
            <div className="text-column">
              <h4>
                We dry all rugs in a controlled drying station to expedite the
                drying process.
              </h4>
              <p>
                Rugs are dried flat on a drying board with airflow on the bottom
                and top of the rug before being hung in a separate drying
                station. We test the moisture levels in all rugs using a
                moisture meter. This ensures that the rugs are completely dry
                before moving along to the next steps in the process.
              </p>
            </div>
          </TwoColumn>
          <TwoColumn>
            <div className="image-column">
              <Img fixed={data.fringeDetailingImage.childImageSharp.fixed} />
            </div>
            <div className="text-column">
              <h4>
                We clean and groom all fringe by hand to restore the fringe to
                its former brightness.
              </h4>
              <p>
                Not only do we scrub and rinse the fringe free of dirt, but we
                also neutralize the pH of the fringe to ensure that it feels
                soft and looks great.
              </p>
            </div>
          </TwoColumn>
          <TwoColumn>
            <div className="image-column">
              <Img fixed={data.rugWrappingImage.childImageSharp.fixed} />
            </div>
            <div className="text-column">
              <h4>
                Rugs are wrapped to protect them from re-soiling until you are
                ready for them to be placed again.
              </h4>
              <p>
                Before wrapping a rug, we test it with moisture meter to ensure
                that it is fully dry and perform a very thorough quality
                inspection.
              </p>
            </div>
          </TwoColumn>
        </section>
        <section className="cta-section">
          <div className="cta-section__inner-wrapper">
            <h2>We offer rug pick-up and delivery services</h2>
            <p>
              Not only will we pick up and deliver your rugs, but we will also
              install them however you want them. This includes placing them
              underneath furniture and aligning them on rug pads where
              necessary.
            </p>
            <h3>
              Call For a Price Quote
              <span className="breaker" />
              <span className="animated-right-arrow">
                <Img fixed={data.arrowRight.childImageSharp.fixed} />
              </span>
              <a href="tel:5404490077">540-449-0077</a>
            </h3>
          </div>
        </section>
        <section id="testimonials" className="testimonial-section">
          <div className="testimonial-section__inner-wrapper">
            <h2>What our clients are saying</h2>
            <blockquote>
              <p>
                I have had the opportunity to use Sci-Tech for a lot of
                different services including basic carpet cleaning, rug
                cleaning, carpet restoration and water extraction and treatment
                due to sewer system back ups. Their customer service is top
                notch, they completely guarantee their work and they are always
                available to help me when I call. As a long time customer of
                Sci-Tech, I fully recommend them for any job!
              </p>
              <p>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
              <footer>April, Blacksburg, VA</footer>
            </blockquote>
            <blockquote>
              <p>
                Very professional and dependable, we highly recommend them for
                your cleaning NEEDS! Thank you.
              </p>
              <p>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
              <footer>The Deans</footer>
            </blockquote>
            <blockquote>
              <p>
                Thank you so much for making our carpet look great again! One of
                your competitors told us the stains would not come out--I
                believed them. (We have two dogs, two cats, and two kids so
                things can be rough!) Apparently not true as our carpets are
                beautiful after the Sci-Tech royal treatment! A friend remarked
                the carpet looks "like new." I am beyond thrilled!
              </p>
              <p>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
              <footer>Cindy</footer>
            </blockquote>
            <blockquote>
              <p>
                Our rugs were clean and fresh. For our allergies and asthma
                that's important. There was no chemical smell. Thank you.
              </p>
              <p>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
              <footer>Anonymous</footer>
            </blockquote>
          </div>
        </section>
        <footer className="page-footer">
          <h2>
            When it comes to caring for your fine rugs, we are simply the best.
          </h2>
          <p>
            Give us a call to get started:{' '}
            <HeroButton href="tel:1-540-449-0077">540-449-0077</HeroButton>
          </p>
          <hr />
          <ThreeColumn>
            <div className="column">
              <h4>Check out our Ad as seen on TV</h4>
              <iframe
                title="rug-cleaning-video"
                width="223"
                height="126"
                className="embed-responsive-item"
                src="//www.youtube.com/embed/ldsa39CYSNg"
              />
            </div>
            <div className="column back-to-section">
              <h4>Back to section</h4>
              <ul>
                <li>
                  <a
                    href="#top-of-page"
                    onClick={ev => {
                      ev.preventDefault();

                      let intervalID = window.setInterval(() => {
                        if (window.scrollY === 0) {
                          window.clearInterval(intervalID);
                        }

                        window.scrollTo(0, window.scrollY - 25);
                      }, 5);
                    }}
                  >
                    Top of page
                  </a>
                </li>
                <li>
                  <a
                    href="#service-benefits"
                    onClick={ev => {
                      ev.preventDefault();

                      let intervalID = window.setInterval(() => {
                        if (
                          window.scrollY <=
                          document.getElementById('service-benefits')
                            .offsetTop -
                            25
                        ) {
                          window.clearInterval(intervalID);
                        }

                        window.scrollTo(0, window.scrollY - 25);
                      }, 5);
                    }}
                  >
                    Benefits
                  </a>
                </li>
                <li>
                  <a
                    href="#multi-step-process"
                    onClick={ev => {
                      ev.preventDefault();

                      let intervalID = window.setInterval(() => {
                        if (
                          window.scrollY <=
                          document.getElementById('multi-step-process')
                            .offsetTop -
                            25
                        ) {
                          window.clearInterval(intervalID);
                        }

                        window.scrollTo(0, window.scrollY - 25);
                      }, 5);
                    }}
                  >
                    Our multi-step process
                  </a>
                </li>
                <li>
                  <a
                    href="#testimonials"
                    onClick={ev => {
                      ev.preventDefault();

                      let intervalID = window.setInterval(() => {
                        if (
                          window.scrollY <=
                          document.getElementById('testimonials').offsetTop - 25
                        ) {
                          window.clearInterval(intervalID);
                        }

                        window.scrollTo(0, window.scrollY - 25);
                      }, 5);
                    }}
                  >
                    Testimonials
                  </a>
                </li>
              </ul>
            </div>
            <div className="column get-in-touch">
              <h4>Get in touch</h4>
              <HeroButton href="tel:1-540-449-0077">540-449-0077</HeroButton>
              <address>
                <span style={{ fontWeight: '700' }}>
                  Sci-Tech Carpet Cleaning
                </span>
                <br /> 740 Radford St.
                <br /> Christiansburg, VA 24073
              </address>
            </div>
          </ThreeColumn>
        </footer>
      </Wrapper>
    </Layout>
  );
};

export default RugCleaningPage;
