import React from 'react';

import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ScrollToTop from '../primespot-ui/components/scrollToTop';

import Styled from 'styled-components';

export default ({ hero, children }) => {
  return (
    <StaticQuery
      query={graphql`
        query landingPageLayoutQuery {
          arrowRight: file(relativePath: { regex: "/arrow-right.png/" }) {
            childImageSharp {
              fixed(width: 16, height: 16) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
    >
      {data => {
        return (
          <LayoutWrapper hero={hero}>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'description',
                  content: data.site.siteMetadata.description,
                },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <div id="top-of-page" />
            <Header arrow={data.arrowRight} />
            {hero}
            <div className="landing-page-layout__content-wrapper">
              {children}
            </div>
            <ScrollToTop />
          </LayoutWrapper>
        );
      }}
    </StaticQuery>
  );
};

const Header = ({ arrow }) => {
  return (
    <HeaderWrapper>
      <span>
        Sci-Tech<span className="hidden-sm"> Carpet Cleaning</span>
      </span>
      <span>
        <span className="hidden-sm">
          Call For a Price Quote
          <Img
            fixed={arrow.childImageSharp.fixed}
            style={{ margin: '2px 6px 0 6px' }}
          />
        </span>
        <a href="tel:1-540-449-0077">540-449-0077</a>
      </span>
    </HeaderWrapper>
  );
};

const LayoutWrapper = Styled.div`
  margin-top: 60px;

  .landing-page-layout__content-wrapper {

  }
`;

const HeaderWrapper = Styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  height: 60px;
  background-color: #f8f8f8;
  border-color: #e7e7e7;
  border-width: 0 0 1px;
  z-index: 1030;
  font-family: lato, sans-serif;
  color: #777;
  font-size: 20px;

  a {
    color: #777;
    text-decoration: none;
  }

  @media screen and (max-width: 640px){
    .hidden-sm {
      display: none;
    }
  }
`;
